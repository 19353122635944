<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="12">
        <base-action-table
          :records="records"
          :fields="fields"
          :busy="isBusy"
          :filtering="filtering"
          :module="module"
          @creating="handleCreating"
          @detail="handleDetail"
          @editing="handleEditing"
          @deleting="handleDeleting"
        >
        </base-action-table>
      </b-col>
    </b-row>
    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-variable-liquidation
          :isDisabled="isDisabled"
          :record="record"
          @save="update"
        />
      </template>
    </base-modal>
  </div>
</template>

<script>
import ActionCRUD from "@/mixins/ActionCRUD";
//Components
import { BCol, BRow, VBTooltip } from "bootstrap-vue";
import BaseActionTable from "@/components/base/BaseActionTable";
import BaseModal from "@/components/base/BaseModal";
import FormVariableLiquidation from "./FormVariableLiquidation";

export default {
  inject: ["variableLiquidationRepository"],
  mixins: [ActionCRUD],
  components: {
    BCol,
    BRow,
    BaseActionTable,
    BaseModal,
    FormVariableLiquidation,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    baby: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      record: null,
      records: [],
      fields: [
        {
          key: "name",
          label: "Nombre",
        },
        { key: "actions", label: "Accion" },
      ],
      isBusy: false,
      filtering: false,
      isDisabled: false,
      id: "modal-add-variable",
      title: "Agregar Variable",
      module: "69961e19-84e7-416a-8143-129c221f5b3f",
    };
  },
  mounted() {
    const me = this;
    me.loadVariables();
  },
  methods: {
    async loadVariables() {
      const me = this;
      try {
        me.isBusy = true;
        const { data } = await me.variableLiquidationRepository.getAll();
        me.records = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy = false;
      }
    },
    handleCreating() {
      const me = this;
      me.title = "Agregar Variable";
      me.isDisabled = false;
      me.record = null;
      me.$bvModal.show(me.id);
    },
    handleEditing(item) {
      const me = this;
      me.title = "Actualizar Variable";
      me.isDisabled = false;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    handleDetail(item) {
      const me = this;
      me.title = "Detalle Variable";
      me.isDisabled = true;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    async handleDeleting(uuid) {
      const me = this
      me.$bvModal.msgBoxConfirm('¿Seguro que deseas eliminar este registro?', {
        title: 'Confirmar eliminación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          if (value) {
            try {
              await me.variableLiquidationRepository.delete(uuid)

              me.messageSuccess('Registro eliminado', 'Registro eliminado con exito');
              me.loadVariables()
            } catch (error) {
              const { message, status } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }
              me.errorSave(message)
            }
            
          }
        })
    },
    update() {
      const me = this;
      me.loadVariables();
      me.$bvModal.hide(me.id);
    },
  },
};
</script>
