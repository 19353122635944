<template>
  <div>
    <b-button
      variant="success"
      class="btn-icon mb-2"
      size="sm"
      :disabled="isDisabled || isDisabled2"
      @click.prevent="addRow()"
    >
      <b-icon-plus-square class="mr-1" />
      Fila
    </b-button>
    <b-row v-if="isVisible">
      <b-col lg="5" md="5" sm="12" class="mb-2 text-center">
        <div role="group">
          <label for="capacity" class="label_input">
            Capacidad
            <b-badge pill variant="badge-chair danger ml-1">
              {{ capacity }}
            </b-badge>
          </label>
        </div>
      </b-col>
      <b-col lg="7" md="7" sm="12" class="mb-2 text-center">
        <div role="group">
          <label class="label_input">
            Sillas agregadas
            <b-badge pill variant="badge-chair success ml-1">
              {{ counterChairs }}
            </b-badge>
          </label>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="rows2.length > 0">
      <b-col
        class="pr-0"
        v-for="(item, index) in rows2"
        :key="index"
      >
        <div class="d-flex align-items-center mb-3">
          <div class="mr-3">
            <div class="subtitle-add-row mr-1">Fila N°</div>
            <b-form-input
              size="sm"
              type="number"
              :id="'row' + index"
              class="text-center __input--number"
              disabled
              :value="index + 1"
            />
          </div>
          <div class="">
            <div class="subtitle-add-row mr-2">N° Sillas</div>
            <b-form-input
              size="sm"
              type="number"
              class="text-center __input--number"
              v-model="item.chairs"
              :disabled="item.status"
              autocomplete="off"
              autofocus
            />
          </div>
          <div>
            <b-button
              variant="danger"
              class="btn_icon btn_icon-row"
              v-if="!item.status"
              @click.prevent="removeRow(index)"
            >
              <b-icon-x />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12" class="text-center">
        <div class="subtitle-add-row">Agrega filas y sillas a tu Transporte.</div>
      </b-col>
    </b-row>
    <b-row v-if="capacity == counterChairs">
      <b-col cols="12" class="d-flex justify-content-center mt-2">
        <b-button
          size="lg"
          class="btn btn-success"
          :disabled="isBusy || $v.$invalid"
          @click.prevent="saveRow"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BSpinner,
  BFormInput,
  BIconPlusSquare,
  BIconX,
  BBadge,
} from "bootstrap-vue";
import { required, numeric } from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUD";

export default {
  inject: ["rowRepository"],
  mixins: [ActionCRUD],
  components: {
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    BIconPlusSquare,
    BIconX,
    BBadge,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    transportationUuid: {
      type: String,
      default: null,
    },
    capacity: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rows2: this.rows,
      isBusy: false,
      isVisible: false,
      counterChairs: 0,
    };
  },
  watch: {
    rows(newData) {
      this.rows2 = [];
      this.rows2 = newData;
    },
  },
  computed: {
    isDisabled2() {
      const me = this;
      let isActive = null;
      let counter = 0;

      me.rows2.forEach((el) => {
        counter += Number(el.chairs);
      });

      me.counterChairs = counter;

      Number(me.capacity) <= counter ? (isActive = true) : (isActive = false);

      if (isActive) {
        me.rows2.forEach((el, index) => {
          if (el.chairs == "") {
            me.rows2.splice(index, 1);
          }
        });
      }

      return isActive;
    },
  },
  validations: {
    rows2: {
      $each: {
        chairs: {
          required,
          numeric,
        },
      },
    },
  },
  methods: {
    addRow() {
      const me = this;
      me.isVisible = true;
      me.rows2.push({
        chairs: "",
        status: false,
      });
    },
    removeRow(index) {
      const me = this;
      me.rows2.splice(index, 1);
      me.rows2.find((el) => el.status == false);
    },
    async saveRow() {
      const me = this;
      me.$bvModal
        .msgBoxConfirm(
          "¿Ya verificaste si agregaste las filas y sillas que corresponden a tu transporte?. Recuerda que esta información no podrá ser modificada.",
          {
            title: "Confirmar",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Si",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            try {
              me.isBusy = true;
              const rows = [];
              me.rows2.forEach((el, index) => {
                if (!el.status) {
                  rows.push({
                    number_row: document.getElementById("row" + index).value,
                    chairs: el.chairs,
                  });
                }
              });

              await me.rowRepository.save(null, {
                rows: rows,
                transportationUuid: me.transportationUuid,
              });
              me.$emit("save");
            } catch (error) {
              const { message, status } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }
              me.errorSave(message)
            } finally {
              me.isBusy = false;
            }
          }
        });
    },
  },
};
</script>

<style lang="css">
.subtitle-add-row {
  color: var(--white-color);
  font-family: var(--fuente-primary);
  font-weight: 500;
  font-size: 16px;
}
.__input--number {
  width: 55px;
  height: 30px;
}
.btn_icon-row {
  padding: 0px !important;
  /* text-align: center;
  position: relative;
  top: 10px; */
}
/* .btn_icon2 {
  color: var(--black-color) !important;
  background-color: transparent !important;
  padding: 0px;
} */
.badge-chair {
  padding: 10px;
  font-size: 14px !important;
}
</style>
