<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="name" class="label_input">Nombre*</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Nombre"
            v-model="formData.name"
            :disabled="isDisable"
            autofocus
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="license_plate" class="label_input">Matrícula*</label>
          <b-form-input
            id="license_plate"
            size="sm"
            placeholder="Matrícula"
            v-model="formData.license_plate"
            :disabled="isDisable"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3" v-if="!record">
        <div role="group">
          <label for="capacity" class="label_input">Capacidad*</label>
          <b-form-input
            id="capacity"
            size="sm"
            placeholder="Capacidad"
            v-model="$v.formData.capacity.$model"
            @blur="$v.formData.capacity.$touch()"
            :disabled="isDisable || isCapacity"
          />
          <span
            v-if="$v.formData.capacity.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.capacity) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3" v-if="!record">
        <div role="group">
          <label class="label_input">Tipo de transporte*</label>
          <v-select
            :options="typeTransportations"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.typeTransportationUuid"
            :reduce="(option) => option.uuid"
            :disabled="isDisable || isCapacity"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer" v-if="!isDisable">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required, numeric } from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUD";

export default {
  inject: ["transportationRepository", "typeTransportationRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  mixins: [ActionCRUD],
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isCapacity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeTransportations: [],
      isBusy: false,
      formData: {
        name: '',
        license_plate: '',
        capacity: '',
        typeTransportationUuid: null,
      },
      repository: "transportationRepository",
    };
  },
  validations: {
    formData: {
      name: {
        required,
      },
      capacity: {
        required,
        numeric,
      },
      license_plate: {
        required,
      },
      typeTransportationUuid: {
        required,
      }
    },
  },
  methods: {
    async loadTypeTransportation() {
      const me = this;
      try {
        const { data } = await me.typeTransportationRepository.getAll();
        me.typeTransportations = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
  },
  async mounted() {
    const me = this;
    await me.loadTypeTransportation();
    if (me.record) {
      me.formData = { ...me.formData, ...me.record };
      delete me.formData.status;
      delete me.formData.enterpriseUuid;
      delete me.formData.row;
      delete me.formData.typeTransportation;
    }
  },
};
</script>
