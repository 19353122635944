<template>
  <div>
    <b-container fluid>
      <button-back />
      <b-row>
        <b-col cols="12">
          <h3>Liquidaciones</h3>
          <ButtonTutotial :module="module" />
        </b-col>
      </b-row>
      <b-card no-body class="card-global">
        <location-table
          :records="records"
          :fields="fields"
          :busy="isBusy"
          :filtering="filtering"
          :module="module"
          :totalSales="totalSales"
          :totalCosts="totalCosts"
          :totalUtility="totalUtility"
          @reloadData="handleLoadDataLiquidation"
          @addVariable="handleAddVariable"
          @detail="handleDetail"
          @editing="handleEditing"
          @deleting="handleDeleting"
        >
        </location-table>
      </b-card>
    </b-container>

    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-liquidation
          :record="record"
          :isDisabled="isDisabled"
          :totalSales="record?.total_sales"
          :travelUuid="record?.travelUuid"
          @save="update"
        />
      </template>
    </base-modal>

    <base-modal title="Listado de Variables" id="modal-list-variables">
      <template v-slot:form>
        <list-variable-liquidation />
      </template>
    </base-modal>
  </div>
</template>

<script>
import { BContainer, BCol, BRow, BCard } from "bootstrap-vue";
import ActionCRUD from "@/mixins/ActionCRUD";
import LocationTable from "./components/LocationTable";
import BaseModal from "@/components/base/BaseModal";
import FormLiquidation from "./components/FormLiquidation";
import ListVariableLiquidation from "./components/ListVariableLiquidation";
import ButtonBack from "@/components/buttons/ButtonBack";
import ButtonTutotial from "@/components/buttons/ButtonTutotial";

export default {
  inject: ["liquidationRepository"],
  mixins: [ActionCRUD],
  components: {
    BContainer,
    BCol,
    BRow,
    BCard,
    LocationTable,
    BaseModal,
    FormLiquidation,
    ButtonBack,
    ListVariableLiquidation,
    ButtonTutotial,
  },
  data() {
    return {
      records: [],
      record: null,
      fields: [
        {
          key: "travel.departure_date",
          label: "Fecha del Tour",
        },
        {
          key: "travel.transportation.name",
          label: "Nombre",
        },
        {
          key: "travel.transportation.typeTransportation.name",
          label: "Tipo Transporte",
        },
        {
          key: "total_sales",
          label: "Total ventas",
        },
        {
          key: "total_costs",
          label: "Total gastos",
        },
        {
          key: "utility",
          label: "Utilidad",
        },
        {
          key: "createdAt",
          label: "Fecha de creación",
        },
        { key: "actions", label: "Accion" },
      ],
      isBusy: false,
      filtering: true,
      isDisabled: false,
      title: "",
      id: "modal-liquidation",
      module: "7d815776-cb9a-43d7-954f-f9e6b96d80fa",
      repository: "liquidationRepository",
      totalSales: 0,
      totalCosts: 0,
      totalUtility: 0,
      start: null,
      end: null,
    };
  },
  async mounted() {},
  methods: {
    async handleLoadDataLiquidation(start, end) {
      const me = this;
      me.start = start;
      me.end = end;
      me.totalSales = 0;
      me.totalCosts = 0;
      me.totalUtility = 0;
      try {
        me.isBusy = true;
        const { data } = await me.liquidationRepository.getAll(start, end);
        me.records = data;
        me.records.forEach((el) => {
          me.totalSales += el.total_sales;
          me.totalCosts += el.total_costs;
          me.totalUtility += el.utility;
        });
      } catch (error) {
        const { status } = error.data;
        me.records = [];
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy = false;
      }
    },
    handleAddVariable() {
      const me = this;
      me.$bvModal.show("modal-list-variables");
    },
    handleEditing(item) {
      const me = this;
      me.$router.push({ name: "ticket", params: { travelUuid: item.travelUuid } });
    },
    handleDetail(item) {
      const me = this;
      me.title = "Detalle Liquidación";
      me.isDisabled = true;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    async handleDeleting(uuid) {
      const me = this;
      me.$bvModal
        .msgBoxConfirm("¿Seguro que deseas eliminar este registro?", {
          title: "Confirmar eliminación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            try {
              await me.liquidationRepository.delete(uuid);

              me.messageSuccess('Registro eliminado', 'Registro eliminado con exito');
              me.handleLoadDataLiquidation(me.start, me.end);
            } catch (error) {
              const { message, status } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }
              me.errorSave(message);
            }
          }
        });
    },
    update() {
      const me = this;
      me.messageSuccess();
      me.handleLoadDataLiquidation(me.start, me.end);
      me.$bvModal.hide(me.id);
    },
  },
};
</script>
