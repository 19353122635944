<template>
  <div>
    <b-container fluid>
      <button-back />
      <b-row>
        <b-col cols="12">
          <h3>Transporte</h3>
          <ButtonTutotial :module="module" />
        </b-col>
      </b-row>
      <b-card no-body class="card-global overflow-hidden">
        <b-row class="mb-4">
          <b-col lg="6" md="6" sm="12">
            <b-row>
              <b-col lg="6" md="6" sm="12">
                <div role="group">
                  <label class="label_input_black">N° de transporte de tú plan</label>
                  <b-form-input size="sm" v-model="number_transportations" disabled />
                </div>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <div role="group">
                  <label class="label_input_black">N° de transporte registradas</label>
                  <b-form-input size="sm" v-model="records.length" disabled />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <base-action-table
          :records="records"
          :fields="fields"
          :busy="isBusy"
          :filtering="filtering"
          :disabled="disabled"
          :module="module"
          @creating="handleCreating"
          @detail="handleDetail"
          @editing="handleEditing"
          @deleting="handleDeleting"
        >
          <template v-slot:extendedactions="data">
            <b-button
              v-if="hasPermission(module, 'chair')"
              variant="secondary"
              class="btn-icon margin-right"
              size="sm"
              @click.prevent="handleChair(data.data.item)"
            >
              <img
                src="@/assets/images/asiento.png"
                width="20px"
                height="20px"
                class="mr-1"
                alt=""
              />
              Sillas
            </b-button>
          </template>
        </base-action-table>
      </b-card>
    </b-container>

    <base-modal :title="title" :id="id" :size="size">
      <template v-slot:form>
        <div v-if="isTransportation">
          <form-transportation
            :record="record"
            :isDisable="isDisable"
            :isCapacity="isCapacity"
            @save="save"
          />
        </div>
        <div v-else>
          <form-row :transportation="transportation" @save="save" />
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import {
  BContainer,
  BCol,
  BRow,
  BButton,
  VBTooltip,
  BFormInput,
  BCard,
} from "bootstrap-vue";
import ActionCRUD from "@/mixins/ActionCRUD";
import BaseActionTable from "@/components/base/BaseActionTable";
import BaseModal from "@/components/base/BaseModal";
import FormTransportation from "./FormTransportation";
import FormRow from "./FormRow";
import ButtonBack from "@/components/buttons/ButtonBack";
import ButtonTutotial from "@/components/buttons/ButtonTutotial";
import PermissionAction from "@/mixins/PermissionAction";
import { mapGetters } from "vuex";

export default {
  inject: ["transportationRepository"],
  mixins: [ActionCRUD, PermissionAction],
  components: {
    BContainer,
    BCol,
    BRow,
    BButton,
    BFormInput,
    BCard,
    BaseActionTable,
    BaseModal,
    FormTransportation,
    FormRow,
    ButtonBack,
    ButtonTutotial,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      records: [],
      record: null,
      fields: [
        {
          key: "name",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "license_plate",
          label: "Matrícula",
          sortable: true,
        },
        {
          key: "capacity",
          label: "Capacidad",
          sortable: true,
        },
        {
          key: "typeTransportation.name",
          label: "Tipo transporte",
          sortable: true,
        },
        { key: "actions", label: "Accion" },
      ],
      isBusy: false,
      filtering: true,
      isDisable: false,
      isCapacity: false,
      disabled: false,
      isTransportation: true,
      title: "Crear transporte",
      id: "transportation-modal",
      module: "ae8d88a5-03b8-4224-8537-01aedfefce73",
      repository: "transportationRepository",
      size: "lg",
      transportation: null,
      number_transportations: "0",
    };
  },
  watch: {
    records() {
      this.validationUserCreate();
    },
  },
  computed: {
    ...mapGetters("user", ["getEnterprise"]),
  },
  async mounted() {
    const me = this;

    await me.handleLoadData();
    me.validationUserCreate();
  },
  methods: {
    handleCreating() {
      const me = this;
      me.title = "Crear transporte";
      me.isDisable = false;
      me.isCapacity = false;
      me.isTransportation = true;
      me.record = null;
      me.size = "lg";
      me.$bvModal.show(me.id);
    },
    handleDetail(item) {
      const me = this;
      me.title = "Detalle embarcación";
      me.isDisable = true;
      me.isTransportation = true;
      me.record = item;
      me.size = "lg";
      me.$bvModal.show(me.id);
    },
    handleEditing(item) {
      const me = this;
      me.title = "Actualizar transporte";
      me.isDisable = false;
      me.isCapacity = true;
      me.isTransportation = true;
      me.record = item;
      me.size = "lg";
      me.$bvModal.show(me.id);
    },
    async handleDeleting(id) {
      await this.del(id);
    },
    handleChair(item) {
      const me = this;
      me.title = "Filas y Sillas";
      me.isDisable = false;
      me.isTransportation = false;
      me.size = "lg";
      me.transportation = {
        name: item.name,
        capacity: item.capacity,
        rows: item.row,
        uuid: item.uuid,
        nameTranportation: item.typeTransportation.name,
      };
      me.$bvModal.show(me.id);
    },
    validationUserCreate() {
      const me = this;
      const { number_transportations } = me.getEnterprise;

      me.number_transportations = number_transportations === "1000" ? "Ilimitados" : number_transportations;

      me.disabled = Number(number_transportations) == me.records.length ? true : false;
    },
  },
};
</script>
