<template>
  <div>
    <b-row>
      <b-col lg="12" sm="12" class="mb-2">
        <div class="contents-button-sales">
          <b-button
            variant="primary"
            class="btn-icon mb-3"
            size="md"
            @click.prevent="() => $emit('addVariable')"
          >
            <b-icon-plus-square class="mr-1" />
            Variables
          </b-button>
          <div class="content-selects">
            <v-select
              :options="years"
              :reduce="(option) => option.number"
              label="name"
              class="style-chooser style-chooser-liquidation"
              placeholder="Año"
              v-model="year"
              @input="getRecords()"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
            <v-select
              :options="months"
              :reduce="(option) => option.number"
              label="name"
              class="style-chooser style-chooser-liquidation"
              placeholder="Mes"
              v-model="month"
              @input="getRecords()"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
        </div>
      </b-col>
      <b-col lg="12" sm="12" class="mb-2">
        <div class="contents-button-sales">
          <div class="card-liquidation">
            <h1>Total ventas mes</h1>
            <h2>{{ formatTotal(totalSales) }}</h2>
          </div>
          <div class="card-liquidation">
            <h1>Total gastos mes</h1>
            <h2>{{ formatTotal(totalCosts) }}</h2>
          </div>
          <div class="card-liquidation">
            <h1>Total Utilidad mes</h1>
            <h2>{{ formatUtility(totalUtility) }}</h2>
          </div>
        </div>
      </b-col>
      <b-col lg="8"></b-col>
      <b-col lg="4" md="8" sm="12" class="mb-3 mt-3">
        <div role="group" class="d-flex">
          <b-form-input
            size="lg"
            v-model="filter"
            type="search"
            placeholder="Buscar"
            class="mr-3"
          />
          <b-input-group-append>
            <b-button
              variant="danger"
              size="md"
              :disabled="!filter"
              @click="filter = ''"
            >
              Limpiar
            </b-button>
          </b-input-group-append>
        </div>
      </b-col>
    </b-row>

    <b-table
      ref="selectableTable"
      striped
      responsive
      bordered
      small
      show-empty
      :fields="fields"
      :items="records"
      :filter="filter"
      :per-page="dataPerPage"
      :current-page="pagination.currentPage"
      :busy="busy"
      :filter-included-fields="filterOn"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Cargando datos...</strong>
        </div>
      </template>
      <template #empty>
        <h6 class="text-center py-2">No hay registros</h6>
      </template>
      <template #cell(total_sales)="data">
        {{ formatTotal(data.item.total_sales) }}
      </template>
      <template #cell(total_costs)="data">
        {{ formatTotal(data.item.total_costs) }}
      </template>
      <template #cell(utility)="data">
        <div :class="{ 'text-danger': isUtilityNegative }">
          {{ isUtilityNegative ? "-" : "" }}
          {{ formatUtility(data.item.utility) }}
        </div>
      </template>
      <template #cell(createdAt)="data">
        <div>
          {{ formatDate(data.item.createdAt) }}
        </div>
      </template>
      <template #cell(actions)="data">
        <b-button
          :id="'p-' + data.item.uuid"
          class="btn-icon actions_ margin-right"
          size="sm"
        >
          <b-icon-three-dots-vertical />
        </b-button>
        <Popover
          :target="'p-' + data.item.uuid"
          :record="data.item"
          :module="module"
          @detail="detailLiquidation"
          @editing="editLiquidation"
          @deleting="deleteLiquidation"
        />
      </template>
    </b-table>
    <b-row v-if="false">
      <b-col cols="12" class="my-1">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          pills
          align="center"
          size="sm"
          class="my-0"
          prev-text="Anterior"
          next-text="Siguiente"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="js">
//Components
import {
  BTable,
  BCol,
  BRow,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BPagination,
  BSpinner,
  BIconThreeDotsVertical,
  BIconPlusSquare,
} from "bootstrap-vue";
import Popover from "@/components/base/Popover";
import { formatPrice, formatDateTime } from "@/utils";
import moment from "moment";
moment.locale("es");
import vSelect from "vue-select";

export default {
  components: {
    BTable,
    BCol,
    BRow,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BPagination,
    BSpinner,
    BIconThreeDotsVertical,
    BIconPlusSquare,
    Popover,
    vSelect,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    busy: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: null,
    },
    totalSales: {
      type: Number,
      default: 0,
    },
    totalCosts: {
      type: Number,
      default: 0,
    },
    totalUtility: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      filter: null,
      pagination: {
        perPage: 10,
        pageOptions: [10, 20, 50],
        totalRows: 1,
        currentPage: 1,
      },
      filterOn: [],
      sortDesc: false,
      sortDirection: "asc",
      isUtilityNegative: false,
      years: [{ name: "2024", number: 2024 }, { name: "2025", number: 2025 }, { name: "2026", number: 2026 }, { name: "2027", number: 2027 }, { name: "2028", number: 2028 }, { name: "2029", number: 2029 }],
      months: [
        {name: "Enero", number: 1},
        {name: "Febrero", number: 2},
        {name: "Marzo", number: 3},
        {name: "Abril", number: 4},
        {name: "Mayo", number: 5},
        {name: "Junio", number: 6},
        {name: "Julio", number: 7},
        {name: "Agosto", number: 8},
        {name: "Septiembre", number: 9},
        {name: "Octubre", number: 10},
        {name: "Noviembre", number: 11},
        {name: "Diciembre", number: 12}
      ],
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1
    };
  },
  computed: {
    dataPerPage() {
      const me = this;
      return me.paginate ? me.pagination.perPage : me.records.length;
    }
  },
  mounted() {
    const me = this;
    me.pagination.totalRows = me.records.length;
    me.findDateStartAndDateEnd(`${me.year}-${me.month}-1`)
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.pagination.currentPage = 1;
    },
    detailLiquidation(item) {
      this.$emit("detail", item);
    },
    editLiquidation(item) {
      this.$emit("editing", item);
    },
    deleteLiquidation(uuid) {
      this.$emit("deleting", uuid);
    },
    formatTotal(value) {
      return formatPrice(value + "");
    },
    formatUtility(value) {
      const me = this;
      me.isUtilityNegative =
        Math.sign(value) === 1 || Math.sign(value) === 0 ? false : true;

      if (me.isUtilityNegative) {
        return formatPrice(value + "");
      }

      return formatPrice(Math.abs(value) + "");
    },
    formatDate(date) {
      return formatDateTime(date)
    },
    getRecords() {
      const me = this;
      me.findDateStartAndDateEnd(`${me.year}-${me.month}-1`)
    },
    findDateStartAndDateEnd(value) {
      const me = this;
      const date = new Date(value).toISOString()
      const start = moment(date).startOf('month').format('YYYY-MM-DD');
      const end = moment(date).endOf('month').format('YYYY-MM-DD');
      me.$emit('reloadData', start, end);
    }
  },
};
</script>
<style lang="css">
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.table > thead {
  background: var(--fourth-color);
  color: var(--white-color);
  font-family: var(--fuente-primary);
  font-size: 16px;
  text-align: center;
}

.table > tbody {
  color: var(--black-color);
  font-family: var(--fuente-primary);
  font-size: 16px;
}

.actions_ {
  background: var(--white-color) !important;
  border: 1px solid var(--second-color) !important;
  color: var(--black-color) !important;
  font-weight: bold !important;
}

.contents-button-sales {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.content-selects {
  display: flex;
  gap: 20px;
  width: 50%;
}

.style-chooser-liquidation {
  width: 30% !important;
}

.style-chooser-liquidation .vs__clear {
  display: none !important;
}

.card-liquidation {
  color: var(--white-color);
  background-image: linear-gradient(
    to right bottom,
    #404c66,
    #404142
  ) !important;
  border-radius: 0.3rem;
  padding: 5px;
}

.card-liquidation h1 {
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.card-liquidation h2 {
  font-size: 28px;
}

/*** VERSION TABLETS ***/
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .style-chooser-liquidation {
    width: 50% !important;
  }
  .content-selects {
    gap: 20px;
    width: 50%;
  }
}
/*** VERSION CELULAR ***/
@media only screen and (max-width: 767px) {
  .contents-button-sales {
    flex-direction: column !important;
  }

  .contents-button-sales button {
    width: 100% !important;
  }

  .style-chooser-liquidation {
    width: 100% !important;
  }
  .content-selects {
    gap: 20px;
    width: 100%;
  }
}
</style>
