<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="name" class="label_input">Nombre*</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Nombre"
            v-model="formData.name"
            :disabled="isDisable"
            autofocus
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer" v-if="!isDisable">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUD";

export default {
  inject: ["variableLiquidationRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
  },
  mixins: [ActionCRUD],
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBusy: false,
      formData: {
        name: '',
      },
      repository: "variableLiquidationRepository",
    };
  },
  validations: {
    formData: {
      name: {
        required,
      },
    },
  },
  async mounted() {
    const me = this;
    if (me.record) {
      me.formData = { ...me.formData, ...me.record };
      delete me.formData.status;
      delete me.formData.enterpriseUuid ;
    }
  },
};
</script>
