<template>
  <div>
    <b-row>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <label for="name" class="text__ mb-0">
          Debe agregar las filas y sillas que correspondan a su <strong>{{ transportation.nameTranportation }}</strong> ya que la
          información no podrá ser modificada por motivos de configuraciones
          internas de la aplicación.
        </label>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <add-row
          :rows="rows2"
          :isDisabled="isDisabled"
          :transportationUuid="transportationUuid"
          :capacity="transportation.capacity"
          @save="updateRows"
        />
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-4">
        <b-row>
          <b-col lg="12" md="12" sm="12" class="mb-3">
            <div class="title text-white text-center mb-0">{{ transportation.nameTranportation }} {{ transportation.name }}</div>
          </b-col>
          <b-col lg="6" md="6" sm="12" class="mb-4">
            <div role="group">
              <label for="capacity" class="label_input">Capacidad</label>
              <b-form-input
                id="capacity"
                size="sm"
                v-model="transportation.capacity"
                disabled
              />
            </div>
          </b-col>
          <b-col lg="6" md="6" sm="12" class="mb-4">
            <div role="group">
              <label class="label_input">Sillas</label>
              <b-form-input size="sm" v-model="number_chairs" disabled />
            </div>
          </b-col>
          <b-col lg="12" md="12" sm="12" class="p-0 m-0">
            <div class="d-flex justify-content-center">
              <div class="board">
                <div v-if="rows.length > 0">
                  <div
                    v-for="(item, index) in rows"
                    :key="index"
                    class="list_chairs"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        v-for="chair in item.chair"
                        :key="chair.uuid"
                        class="btn-icon __chair margin_right"
                        size="sm"
                      >
                        {{ chair.number_chair }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import AddRow from "./AddRow";
import { required, numeric } from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUD";

export default {
  inject: ["rowRepository", "chairRepository"],
  mixins: [ActionCRUD],
  components: {
    BCol,
    BRow,
    BButton,
    BFormInput,
    AddRow,
  },
  props: {
    transportation: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      transportationUuid: null,
      rows: [],
      rows2: [],
      record: null,
      number_chairs: null,
      isDisabled: false,
    };
  },
  validations: {
    rows2: {
      $each: {
        chairs: {
          required,
          numeric,
        },
      },
    },
  },
  methods: {
    async loadRows() {
      const me = this;
      me.rows = [];
      me.rows2 = [];
      try {
        const { data } = await me.rowRepository.getAll(me.transportation.uuid);
        me.rows = data;
        me.rows.length > 0 ? me.isDisabled = true : me.isDisabled = false
        me.countChairs(me.rows);
        me.rows.sort((a, b) => {
          if (parseInt(a.number_row) > parseInt(b.number_row)) {
            return 1;
          }
          if (parseInt(a.number_row) < parseInt(b.number_row)) {
            return -1;
          }
          return 0;
        });

        me.rows.map((el) => {
          el.chair.sort((a, b) => {
            if (parseInt(a.number_chair) > parseInt(b.number_chair)) {
              return 1;
            }
            if (parseInt(a.number_chair) < parseInt(b.number_chair)) {
              return -1;
            }
            return 0;
          });
        });

        me.rows.map((el) => {
          me.rows2.push({
            uuid: el.uuid,
            number_row: el.number_row,
            chairs: el.chair.length,
            status: el.status,
          });
        });
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    updateRows() {
      const me = this;
      me.messageSuccess();
      me.loadRows();
    },
    countChairs(rows) {
      const me = this;
      let chairs = [];
      if (rows.length > 0) {
        rows.forEach((el) => {
          chairs.push(el.chair.length);
        });
        me.number_chairs = chairs.reduce((a, b) => a + b, 0);
      } else {
        me.number_chairs = 0;
      }

      // me.number_chairs === Number(me.transportation.capacity)
      //   ? (me.isDisabled = true)
      //   : (me.isDisabled = false);
    },
  },
  mounted() {
    const me = this;
    me.loadRows();
    me.transportationUuid = me.transportation.uuid;
  },
};
</script>

<style lang="css" scoped>
.margin_right {
  margin-right: 3.5px;
}

.title {
  color: var(--black-color);
  font-family: var(--fuente-primary);
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 18px;
}

.subtitle {
  color: var(--fourth-color);
  font-family: var(--fuente-primary);
  font-weight: 700;
  font-size: 16px;
}

.__input--number {
  width: 55px;
  height: 30px;
}

.btn_icon {
  padding: 0px;
  text-align: center;
  position: relative;
  top: 10px;
  /* height: 30px; */
}

.line__space {
  margin-top: 8px;
  border: 2px solid var(--gray-light-color);
  width: 100%;
}

.board {
  border: 4px solid #b85f85;
  border-top-right-radius: 20%;
  border-top-left-radius: 20%;
  width: auto;
  height: auto;
  padding: 10px;
}

.list_chairs {
  /* position: relative;
  top: 90px; */
  padding: 5px;
}

.__chair {
  background: var(--white-color) !important;
  border: 1px solid var(--third-color) !important;
  color: var(--black-color) !important;
  font-weight: bold !important;
}

.__chair:hover {
  color: var(--black-color) !important;
  background: #8a8989;
}

.btn_edit {
  cursor: pointer;
}
/* ............. */
/*Media querys*/
/*** VERSION ESCRITORIO Y TABLETS PANTALLS GRANDE ***/
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/*** VERSION TABLETS ***/
@media only screen and (min-width: 768px) and (max-width: 992px) {
}

/*** VERSION CELULAR ***/
@media only screen and (max-width: 767px) {
  .__chair {
    width: 36px;
    height: 36px;
  }
}

/*** VERSION CELULAR PEQUEÑO ***/
@media only screen and (max-width: 340px) {
}
</style>
